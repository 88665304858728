import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import BillingRecalculateRequest, {
  BillingRecalculateRequestBonusContribution,
  BillingRecalculateRequestContribution,
  BillingRecalculateRequestContributionType,
  BillingRecalculateRequestPenaltyContribution,
} from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    billing_contributions: createBillingContributions(
      data.billingContributions
    ),
    bonus: 0,
    penalty: 0,
    bonus_type: data.bonusType,
    penalty_type: data.penaltyType,
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate?.toJSON() ?? null,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };
    return node;
  });
};

const createBillingBonusContribution = (
  contribution: BillingsTaxiTaxiContributionBonus
): BillingRecalculateRequestBonusContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingRecalculateRequestContributionType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance ?? 0,
        rate: contribution.rate ?? 0,
      };
    case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER:
      return {
        type: BillingRecalculateRequestContributionType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingsTaxiTaxiContributionPenalty
): BillingRecalculateRequestPenaltyContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_BEING_LATE:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_BEING_LATE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_INCOMPATIBLE_CAR,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_OTHER:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiTaxiContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_WRONG_ROUTE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
  }
};

const createBillingContributions = (
  contributions: BillingFormDataContributions
): BillingRecalculateRequestContribution[] => {
  const bonusContributions: BillingRecalculateRequestBonusContribution[] =
    contributions.bonus
      .filter((contribution) => contribution.type !== null)
      .map((contribution) => createBillingBonusContribution(contribution)!);

  const penaltyContributions: BillingRecalculateRequestPenaltyContribution[] =
    contributions.penalty
      .filter((contribution) => contribution.type !== null)
      .map((contribution) => createBillingPenaltyContribution(contribution)!);

  return [...bonusContributions, ...penaltyContributions];
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
