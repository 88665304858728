import BillingsCargoTaxiContributions, {
  BillingsCargoTaxiContributionDiscountType,
} from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingRecalculateRequest, {
  BillingRecalculateRequestContribution,
  BillingRecalculateRequestContributionType,
} from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createRecalculateContributionType = (
  contributionType:
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER
):
  | BillingRecalculateRequestContributionType.DISCOUNT_BEING_LATE
  | BillingRecalculateRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR
  | BillingRecalculateRequestContributionType.DISCOUNT_OPTIMIZATION
  | BillingRecalculateRequestContributionType.DISCOUNT_OTHER => {
  switch (contributionType) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return BillingRecalculateRequestContributionType.DISCOUNT_BEING_LATE;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingRecalculateRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return BillingRecalculateRequestContributionType.DISCOUNT_OPTIMIZATION;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return BillingRecalculateRequestContributionType.DISCOUNT_OTHER;
  }
};

const createBillingDiscountContribution = (
  contribution: BillingsCargoTaxiContributions
): BillingRecalculateRequestContribution | undefined => {
  switch (contribution.type) {
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        type: BillingRecalculateRequestContributionType.CONTRACT_CORRECTION_PERCENTAGE,
        percentage: contribution.percentage ?? 0,
        amount: contribution.amount ?? 0,
      };
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return {
        type: createRecalculateContributionType(contribution.type),
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingContributions = (
  contributions: BillingFormDataContributions
): BillingRecalculateRequestContribution[] => {
  const discountContributions = contributions.discount
    .filter((contribution) => contribution.type !== null)
    .map((contribution) => createBillingDiscountContribution(contribution)!);

  return [...discountContributions];
};

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    discount: 0,
    fuel_additive_rate: 0,
    billing_contributions: createBillingContributions(
      data.billingContributions
    ),
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate!,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate!,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };
    return node;
  });
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
