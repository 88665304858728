import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: true,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: true,
    [MessengerChannel.SHARED]: true,
    [MessengerChannel.TAXI]: true,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const billingsCargoRailyHelper = { getMessengerChannelAvailability };

export default billingsCargoRailyHelper;
