import BillingModel from "../../../types/billing-model";
import {
  BillingData,
  BillingDataResponseBonusContribution,
  BillingDataResponseContractCorrectionContribution,
  BillingDataResponseContribution,
  BillingDataResponseContributionType,
  BillingDataResponsePenaltyContribution,
} from "../api/billing-data.response";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionsBonusTableRow from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus-table-row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import TableButtonComponent from "../../../../../common/components/table/button/table-button.component";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import BillingsTaxiDriverContributionsPenaltyTableRow from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty-table-row";
import BillingsTaxiDriverContributionsContractCorrectionTableRow from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction-table-row";
import TabsData from "../../../../../common/components/tabs/common/types/tabs-data";
import BillingsTaxiDriverContributionsBonusTableComponent from "../../common/contributions/bonus/table/billings-taxi-driver-contributions-bonus-table.component";
import BillingsTaxiDriverContributionsPenaltyTableComponent from "../../common/contributions/penalty/table/billings-taxi-driver-contributions-penalty-table.component";
import BillingsTaxiDriverContributionsContractCorrectionTableComponent from "../../common/contributions/contract-correction/table/billings-taxi-driver-contributions-contract-correction-table.component";
import BillingContributionType from "../types/billing-contribution-type";
import BillingContributionsValidationResult from "../types/billing-contributions-validation-result";
import billingsTaxiDriverHelper from "../billing-taxi-driver.helper";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((billingNode, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsRouteService.createGoogleMapsRouteUrl([
            { latitude: previousItem.lat, longitude: previousItem.lon },
            { latitude: billingNode.lat, longitude: billingNode.lon },
          ])
        : "";

    const result: BillingsNode = {
      id: billingNode.id,
      nodeId: billingNode.node_id,
      lat: billingNode.lat,
      lon: billingNode.lon,
      description: billingNode.description,
      checkoutDate: billingNode.checkout_date
        ? new Date(billingNode.checkout_date)
        : null,
      checkoutEventUuid: billingNode.checkout_event_id,
      plannedDate: billingNode.planned_date
        ? new Date(billingNode.planned_date)
        : null,
      plannedDistance: billingNode.planned_distance,
      orderId: billingNode.order_id,
      distance: billingNode.distance,
      haltingTime: billingNode.halting_time,
      haltingAmount: billingNode.halting_amount,
      highwayCharge: billingNode.highway_charge,
      isHighwayAllowed: billingNode.allow_charge,
      isEditable: billingNode.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingPenaltyContributionType = (
  contributionType: BillingDataResponseContributionType
): BillingsTaxiDriverContributionPenaltyType | undefined => {
  switch (contributionType) {
    case BillingDataResponseContributionType.PENALTY_WRONG_ROUTE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE;
    case BillingDataResponseContributionType.PENALTY_BEING_LATE:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE;
    case BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR;
    case BillingDataResponseContributionType.PENALTY_OTHER:
      return BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER;
    default:
      return undefined;
  }
};

const createBillingBonusContribution = (
  contribution: BillingDataResponseBonusContribution
): BillingsTaxiDriverContributionBonus => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance,
        rate: contribution.rate,
      };
    case BillingDataResponseContributionType.BONUS_OTHER:
      return {
        type: BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount,
        distance: null,
      };
  }
};

const createBillingContractCorrectionContribution = (
  contribution: BillingDataResponseContractCorrectionContribution
): BillingsTaxiDriverContributionContractCorrection => {
  switch (contribution.type) {
    case BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        type: BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
        distance: contribution.distance,
        amount: contribution.amount,
      };
    case BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE:
      return {
        type: BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
        rate: contribution.rate,
      };
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingDataResponsePenaltyContribution
): BillingsTaxiDriverContributionPenalty => {
  return {
    type: createBillingPenaltyContributionType(contribution.type) ?? null,
    comment: contribution.comment ?? "",
    amount: contribution.amount,
  };
};

const createBillingContributions = (
  contributions: BillingDataResponseContribution[]
): BillingFormDataContributions => {
  const bonusContributions: BillingsTaxiDriverContributionBonus[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BONUS_FAVORABLE_DISTANCE,
          BillingDataResponseContributionType.BONUS_OTHER,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingBonusContribution(
          contribution as BillingDataResponseBonusContribution
        )
      );

  const penaltyContributions: BillingsTaxiDriverContributionPenalty[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.PENALTY_BEING_LATE,
          BillingDataResponseContributionType.PENALTY_INCOMPATIBLE_CAR,
          BillingDataResponseContributionType.PENALTY_OTHER,
          BillingDataResponseContributionType.PENALTY_WRONG_ROUTE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingPenaltyContribution(
          contribution as BillingDataResponsePenaltyContribution
        )
      );

  const contractCorrectionContribution: BillingsTaxiDriverContributionContractCorrection[] =
    contributions
      .filter((contribution) =>
        [
          BillingDataResponseContributionType.BASE_AMOUNT_EXTERNAL_TAXI,
          BillingDataResponseContributionType.CONTRACT_CORRECTION_RATE,
        ].includes(contribution.type)
      )
      .map((contribution) =>
        createBillingContractCorrectionContribution(
          contribution as BillingDataResponseContractCorrectionContribution
        )
      );

  return {
    bonus: bonusContributions,
    penalty: penaltyContributions,
    contractCorrection: contractCorrectionContribution,
  };
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    billingContributions: createBillingContributions(
      data.billing_contributions
    ),
    contractDetails: {
      driverName: `${data.contract.taxi_driver_association.driver.user.first_name} ${data.contract.taxi_driver_association.driver.user.last_name}`,
      driverStartingAddress:
        data.contract.taxi_driver_association.driver.starting_address
          .display_name,
      fleetPartner: data.contract.taxi_driver_association.fleet_partner?.name,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
      notes: data.contract.description,
    },
    baseAmount: data.base_amount,
    haltingCost: data.amount_for_charge_haltings,
    highwayCharge: data.amount_for_charge_highways,
    distance: data.distance,
    routeDistanceCost: data.amount_for_distance,
    internalOrderId: data.plan_entry.human_id,
    total: data.all_contributions_amount,
    planEntryId: data.plan_entry_id,
    bonus: 0,
    penalty: 0,
    penaltyType: data.penalty_type,
    bonusType: data.bonus_type,
    date: data.date,
    solvedOrderUuids: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.id
    ),
    solvedOrderHumanIds: data.plan_entry.solved_orders.map(
      (solvedOrder) => solvedOrder.transporting_order.cargo_order.human_id
    ),
    passengers: data.passengers,
    driver: data.contract.taxi_driver_association.driver.user,
    rateForDistance: data.rate_for_distance,
    correctedContractModel: data.corrected_contract_model,
    favorableDistance: data.favorable_distance,
    sumOfBonuses: data.sum_of_bonuses,
    sumOfPenalties: data.sum_of_penalties,
  };

  return billingDraft;
};

const createContributionsBonusTableRow = (
  contribution: BillingsTaxiDriverContributionBonus,
  contributions: BillingsTaxiDriverContributionBonus[],
  position: number,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceChange: (
    position: number,
    distance: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateChange: (
    position: number,
    rate: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): BillingsTaxiDriverContributionsBonusTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const typeSelectOptions =
    billingsTaxiDriverHelper.getContributionsBonusTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult = contributionsValidationResults.bonus.find(
    (validation) => validation.position === position
  );

  switch (contribution.type) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_row"
              title={String(contribution.distance)}
            >
              <NumericInputComponent
                value={contribution.distance}
                onChange={(value) =>
                  onContributionDistanceChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionDistanceBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.distancePlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-distance-${position}`}
                isIntegerOnly
                hasError={!!validationResult?.distance.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.distance.errorMessage}
              />
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_row"
              title={String(contribution.rate)}
            >
              <NumericInputComponent
                value={contribution.rate}
                onChange={(value) =>
                  onContributionRateChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionRateBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.ratePlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-rate-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.rate.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.rate.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_row"
              title={contribution.comment}
            >
              <InputComponent
                value={contribution.comment}
                onChange={(value) =>
                  onContributionCommentChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionCommentBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.commentPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-comment-${position}`}
                hasError={!!validationResult?.comment.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position, BillingContributionType.BONUS);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_not_required_row"
              title={translations.attributeNotApplicable}
            >
              {translations.attributeNotApplicable}
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) =>
                  onContributionAmountChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionAmountBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.amountPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-amount-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.amount.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.amount.errorMessage}
              />
            </div>
          ),
          comment: (
            <div
              className="billings_contributions_row"
              title={contribution.comment}
            >
              <InputComponent
                value={contribution.comment}
                onChange={(value) =>
                  onContributionCommentChange(
                    position,
                    value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionCommentBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                placeholder={translations.bonus.form.commentPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-comment-${position}`}
                hasError={!!validationResult?.comment.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.comment.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(position, BillingContributionType.BONUS);
                }}
                type="danger"
                title={
                  translations.bonus.table.actions.deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(position),
        value: {
          type: (
            <div>
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.BONUS
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.BONUS
                  )
                }
                options={typeSelectOptions}
                placeholder={translations.bonus.form.typeSelectPlaceholder}
                idForTesting={`billings-taxi-driver-add-contribution-bonus-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: <div></div>,
          rate: <div></div>,
          amount: <div></div>,
          comment: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createContributionsPenaltyTableRow = (
  contribution: BillingsTaxiDriverContributionPenalty,
  contributions: BillingsTaxiDriverContributionPenalty[],
  position: number,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): BillingsTaxiDriverContributionsPenaltyTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const typeSelectOptions =
    billingsTaxiDriverHelper.getContributionsPenaltyTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult = contributionsValidationResults.penalty.find(
    (validation) => validation.position === position
  );

  return {
    id: `${contribution.type}-${position}`,
    value: {
      type: (
        <div className="billings_contributions_row">
          <SingleSelectComponent
            value={typeSelectedOption}
            onChange={(value) =>
              onContributionTypeChange(
                position,
                value?.value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionTypeBlur(position, BillingContributionType.PENALTY)
            }
            options={typeSelectOptions}
            placeholder={translations.penalty.form.typeSelectPlaceholder}
            idForTesting={`billings-taxi-driver-add-contribution-penalty-type-select-${position}`}
            filterFunction={(option) =>
              !contributions.find(
                (contribution) => option.value == contribution.type
              )
            }
            hasError={!!validationResult?.type.errorMessage}
            autoFocus
          />
          <FormErrorComponent message={validationResult?.type.errorMessage} />
        </div>
      ),
      amount: (
        <div
          className="billings_contributions_row"
          title={String(contribution.amount)}
        >
          <NumericInputComponent
            value={contribution.amount}
            onChange={(value) =>
              onContributionAmountChange(
                position,
                value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionAmountBlur(
                position,
                BillingContributionType.PENALTY
              )
            }
            placeholder={translations.penalty.form.amountPlaceholder}
            idForTesting={`billings-taxi-driver-add-contribution-penalty-amount-${position}`}
            decimalPrecision={2}
            hasError={!!validationResult?.amount.errorMessage}
          />
          <FormErrorComponent message={validationResult?.amount.errorMessage} />
        </div>
      ),
      comment: (
        <div
          className="billings_contributions_row"
          title={contribution.comment}
        >
          <InputComponent
            value={contribution.comment}
            onChange={(value) =>
              onContributionCommentChange(
                position,
                value,
                BillingContributionType.PENALTY
              )
            }
            onBlur={() =>
              onContributionCommentBlur(
                position,
                BillingContributionType.PENALTY
              )
            }
            placeholder={translations.penalty.form.commentPlaceholder}
            idForTesting={`billings-taxi-driver-add-contribution-penalty-comment-${position}`}
            hasError={!!validationResult?.comment.errorMessage}
          />
          <FormErrorComponent
            message={validationResult?.comment.errorMessage}
          />
        </div>
      ),
      actions: (
        <div className="billings_contributions_delete_button">
          <TableButtonComponent
            icon={faTrash}
            onClick={() => {
              deleteContribution(position, BillingContributionType.PENALTY);
            }}
            type="danger"
            title={
              translations.penalty.table.actions.deleteContributionButtonTitle
            }
          />
        </div>
      ),
    },
  };
};

const createContributionsContractCorrectionTableRow = (
  contribution: BillingsTaxiDriverContributionContractCorrection,
  contributions: BillingsTaxiDriverContributionContractCorrection[],
  billingFormData: BillingFormData,
  position: number,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateChange: (
    position: number,
    rate: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): BillingsTaxiDriverContributionsContractCorrectionTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const typeSelectOptions =
    billingsTaxiDriverHelper.getContributionsContractCorrectionTypeSelectOptions();

  const typeSelectedOption =
    typeSelectOptions.find((option) => option.value === contribution.type) ??
    null;

  const validationResult =
    contributionsValidationResults.contractCorrection.find(
      (validation) => validation.position === position
    );

  switch (contribution.type) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                options={typeSelectOptions}
                placeholder={
                  translations.contractCorrection.form.typeSelectPlaceholder
                }
                idForTesting={`billings-taxi-driver-add-contribution-contract-correction-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_not_required_row"
              title={String(contribution.distance)}
            >
              {contribution.distance}
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_not_required_row"
              title={(
                (contribution.amount ?? 0) / (contribution.distance ?? 0)
              ).toFixed(2)}
            >
              {Number(
                (
                  (contribution.amount ?? 0) / (contribution.distance ?? 0)
                ).toFixed(2)
              )}
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_row"
              title={String(contribution.amount)}
            >
              <NumericInputComponent
                value={contribution.amount}
                onChange={(value) =>
                  onContributionAmountChange(
                    position,
                    value,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                onBlur={() =>
                  onContributionAmountBlur(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                placeholder={
                  translations.contractCorrection.form.amountPlaceholder
                }
                idForTesting={`billings-taxi-driver-add-contribution-contract-correction-amount-${position}`}
                decimalPrecision={2}
                hasError={!!validationResult?.amount.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.amount.errorMessage}
              />
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  );
                }}
                type="danger"
                title={
                  translations.contractCorrection.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return {
        id: `${contribution.type}-${position}`,
        value: {
          type: (
            <div className="billings_contributions_row">
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                options={typeSelectOptions}
                placeholder={
                  translations.contractCorrection.form.typeSelectPlaceholder
                }
                idForTesting={`billings-taxi-driver-add-contribution-contract-correction-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          distance: (
            <div
              className="billings_contributions_not_required_row"
              title={String(billingFormData.distance)}
            >
              {billingFormData.distance}
            </div>
          ),
          rate: (
            <div
              className="billings_contributions_row"
              title={String(contribution.rate)}
            >
              <NumericInputComponent
                value={contribution.rate}
                onChange={(value) =>
                  onContributionRateChange(
                    position,
                    value,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                onBlur={() =>
                  onContributionRateBlur(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                placeholder={
                  translations.contractCorrection.form.ratePlaceholder
                }
                decimalPrecision={2}
                hasError={!!validationResult?.rate.errorMessage}
              />
              <FormErrorComponent
                message={validationResult?.rate.errorMessage}
              />
            </div>
          ),
          amount: (
            <div
              className="billings_contributions_not_required_row"
              title={(
                (billingFormData.distance ?? 0) * (contribution.rate ?? 0)
              ).toFixed(2)}
            >
              {(
                (billingFormData.distance ?? 0) * (contribution.rate ?? 0)
              ).toFixed(2)}
            </div>
          ),
          actions: (
            <div className="billings_contributions_delete_button">
              <TableButtonComponent
                icon={faTrash}
                onClick={() => {
                  deleteContribution(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  );
                }}
                type="danger"
                title={
                  translations.contractCorrection.table.actions
                    .deleteContributionButtonTitle
                }
              />
            </div>
          ),
        },
      };
    default:
      return {
        id: String(position),
        value: {
          type: (
            <div>
              <SingleSelectComponent
                value={typeSelectedOption}
                onChange={(value) =>
                  onContributionTypeChange(
                    position,
                    value?.value,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                onBlur={() =>
                  onContributionTypeBlur(
                    position,
                    BillingContributionType.CONTRACT_CORRECTION
                  )
                }
                options={typeSelectOptions}
                placeholder={
                  translations.contractCorrection.form.typeSelectPlaceholder
                }
                idForTesting={`billings-taxi-driver-add-contribution-contract-correction-type-select-${position}`}
                filterFunction={(option) =>
                  !contributions.find(
                    (contribution) => option.value == contribution.type
                  )
                }
                hasError={!!validationResult?.type.errorMessage}
                autoFocus
              />
              <FormErrorComponent
                message={validationResult?.type.errorMessage}
              />
            </div>
          ),
          amount: <div></div>,
          distance: <div></div>,
          rate: <div></div>,
          actions: <div className="d-flex"></div>,
        },
      };
  }
};

const createTabsData = (
  contributions: BillingFormDataContributions,
  isDataLoading: boolean,
  billingFormData: BillingFormData,
  deleteContribution: (position: number, type: BillingContributionType) => void,
  contributionsValidationResults: BillingContributionsValidationResult,
  onContributionTypeChange: (
    position: number,
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceChange: (
    position: number,
    distance: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateChange: (
    position: number,
    rate: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountChange: (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentChange: (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => void,
  onContributionTypeBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionDistanceBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionRateBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionAmountBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void,
  onContributionCommentBlur: (
    position: number,
    contributionType: BillingContributionType
  ) => void
): TabsData => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

  const bonusTableRows: BillingsTaxiDriverContributionsBonusTableRow[] =
    contributions.bonus.map((contribution, index) =>
      createContributionsBonusTableRow(
        contribution,
        contributions.bonus,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionDistanceChange,
        onContributionRateChange,
        onContributionAmountChange,
        onContributionCommentChange,
        onContributionTypeBlur,
        onContributionDistanceBlur,
        onContributionRateBlur,
        onContributionAmountBlur,
        onContributionCommentBlur
      )
    );

  const penaltyTableRows: BillingsTaxiDriverContributionsPenaltyTableRow[] =
    contributions.penalty.map((contribution, index) =>
      createContributionsPenaltyTableRow(
        contribution,
        contributions.penalty,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionAmountChange,
        onContributionCommentChange,
        onContributionTypeBlur,
        onContributionAmountBlur,
        onContributionCommentBlur
      )
    );

  const contractCorrectionTableRows: BillingsTaxiDriverContributionsContractCorrectionTableRow[] =
    contributions.contractCorrection.map((contribution, index) =>
      createContributionsContractCorrectionTableRow(
        contribution,
        contributions.contractCorrection,
        billingFormData,
        index,
        deleteContribution,
        contributionsValidationResults,
        onContributionTypeChange,
        onContributionRateChange,
        onContributionAmountChange,
        onContributionTypeBlur,
        onContributionRateBlur,
        onContributionAmountBlur
      )
    );

  const BonusTableContent = (
    <BillingsTaxiDriverContributionsBonusTableComponent
      isLoading={isDataLoading}
      rows={bonusTableRows}
    />
  );

  const PenaltyTableContent = (
    <BillingsTaxiDriverContributionsPenaltyTableComponent
      isLoading={isDataLoading}
      rows={penaltyTableRows}
    />
  );

  const ContractCorrectionTableContent = (
    <BillingsTaxiDriverContributionsContractCorrectionTableComponent
      isLoading={isDataLoading}
      rows={contractCorrectionTableRows}
    />
  );

  const tabsData: TabsData = [
    {
      label: translations.bonus.title,
      content: BonusTableContent,
      counter: contributions.bonus.filter((contribution) => contribution.type)
        .length,
      totalTableRows: bonusTableRows.length,
    },
    {
      label: translations.penalty.title,
      content: PenaltyTableContent,
      counter: contributions.penalty.filter((contribution) => contribution.type)
        .length,
      totalTableRows: penaltyTableRows.length,
    },
    {
      label: translations.contractCorrection.title,
      content: ContractCorrectionTableContent,
      counter: contributions.contractCorrection.filter(
        (contribution) => contribution.type
      ).length,
      totalTableRows: contractCorrectionTableRows.length,
    },
  ];

  return tabsData;
};

const billingDataFactory = {
  createBillingData,
  createTabsData,
};

export default billingDataFactory;
