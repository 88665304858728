import MileageDetailsPassenger from "./types/mileage-details-passenger";
import MileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";

const getPassengerTitle = (passenger: MileageDetailsPassenger): string => {
  const translations =
    MileageTranslationsHelper.getMileageDetailsTranslations();

  return translations.passengers.passengerTitleTemplateText
    .replace("#{passenger}", passenger.displayName)
    .replace("#{mobileNumber}", passenger.phoneNumber);
};

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.MILEAGE_PASSENGER]: true,
    [MessengerChannel.MILEAGE_NOTES]: true,
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: false,
    [MessengerChannel.DRIVER]: false,
    [MessengerChannel.RAILY]: false,
    [MessengerChannel.SHARED]: false,
    [MessengerChannel.TAXI]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const mileageDetailsHelper = {
  getPassengerTitle,
  getMessengerChannelAvailability,
};

export default mileageDetailsHelper;
