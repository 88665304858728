import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BillingCargoTaxiAddRouteParams from "../../common/routes/types/billing-cargo-taxi-add-route-params";
import { useAppContext } from "../../../../context/app.context";
import BillingFormData from "./types/billing-form.data";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import BillingRouteDetailsFormDataValidationResult, {
  NodeValidationResult,
} from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import BillingDataResponse from "./api/billing-data.response";
import BillingGpsResponse from "./api/billing-gps-data.response";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import BillingSaveResponse from "./api/billing-save.response";
import notificationService from "../../../../common/utils/notification/notification.service";
import billingDataFactory from "./factory/billing-data.factory";
import billingsRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsNode from "../../common/types/billings-node";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsCargoTaxiHelper from "./billings-cargo-taxi.helper";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MapComponent from "../../../../common/components/map/map.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import CardComponent from "../../../../common/components/card/card.component";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/taxi-driver/related-billings-taxi-driver.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import MessengerBillingType from "../../../../common/components/messenger/types/messenger-billing-type";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import billingsApiService from "./api/billings-api.service";
import billingApiService from "./api/billings-cargo-taxi-api.service";
import BillingsCargoTaxiContributions, {
  BillingsCargoTaxiContributionContractCorretionPercentage,
  BillingsCargoTaxiContributionDiscountBeingLate,
  BillingsCargoTaxiContributionDiscountIncompatibleCar,
  BillingsCargoTaxiContributionDiscountOptimization,
  BillingsCargoTaxiContributionDiscountOther,
  BillingsCargoTaxiContributionDiscountType,
} from "../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import billingCargoTaxiContributionsDiscountValidationService from "../common/contributions/billings-cargo-taxi-contributions-discount-validation.service";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";
import BillingContributionValidationResult from "./types/billing-contributions-validation-result";
import useApiCall from "../../common/hooks/use-api-call";

type BillingsCargoTaxiAddProps = {};

const BillingsCargoTaxiAddComponent: FC<BillingsCargoTaxiAddProps> = () => {
  const { orderUuid } = useParams<BillingCargoTaxiAddRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [orderInternalId, setOrderInternalId] = useState("");

  const [contributionsValidationResults, setContributionsValidationResults] =
    useState<BillingContributionValidationResult>({ discount: [] });

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();
  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [activeTab, setActiveTab] = useState(0);

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  const [formValidationResults, setFormValidationResults] =
    useState<BillingRouteDetailsFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
    });

  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsCargoWithTaxiAdd.replace("#{orderInternalId}", orderInternalId);

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs = billingBreadcrumbsHelper.getCargoWithTaxiAddBreadcrumbs(
      {
        orderUuid: orderUuid!,
        orderInternalId,
      }
    );
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, orderInternalId]);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(orderUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, []);

  useEffect(() => {
    billingsApiService.fetchGpsData(orderUuid!).then(handleGpsDataResponse);
  }, []);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
      setShouldRetrieveRouteData(false);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBillingFunction = (formData: BillingFormData) => {
    const recalculateRequest =
      billingRecalculateRequestFactory.createRecalculateRequest(formData!);

    billingApiService
      .recalculateBilling(orderUuid!, recalculateRequest)
      .then(handleRecalculateResponse);
  };

  const recalculateBilling = useApiCall(recalculateBillingFunction, 50);
  const deleteContributionRecalculateBilling = useApiCall(
    recalculateBillingFunction,
    250
  );

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getTaxiListingOfUnsettledOrdersRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setOrderInternalId(String(response.data.cargo_order.human_id));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          haltingTime: formValidationService.defaultValidationResult,
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
    }));

    const contributionsValidationResult: BillingContributionValidationResult = {
      discount: billingData.billingContributions.discount.map(
        (contribution, index) => ({
          position: index,
          type: formValidationService.defaultValidationResult,
          amount: formValidationService.defaultValidationResult,
          comment: formValidationService.defaultValidationResult,
          percentage: formValidationService.defaultValidationResult,
        })
      ),
    };

    setContributionsValidationResults(contributionsValidationResult);
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingsRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    handleNodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    if (!billingFormData) {
      return;
    }

    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingsRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const areContributionsValid = validateContributions();

    const isFormValid =
      areContributionsValid && areNodesValid && validateDiscount();

    if (!isFormValid) {
      onModalClose();

      notificationService.error(translations.failureValidationNotificationText);
      return;
    }

    if (billingData) {
      setIsBillingSavePending(true);

      const data =
        billingSaveRequestFactory.createSaveBillingRequest(billingFormData);

      billingApiService
        .saveBillingData(orderUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingsRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    handleNodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const handleNodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onDiscountBlur = () => {
    const isDiscountValid = validateDiscount();

    if (isDiscountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateDiscount = () => {
    const maximumDiscountValue = billingData?.contractDetails?.discountLimit
      ? billingData?.contractDetails?.discountLimit
      : 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validateDiscount(
        billingFormData?.discount ?? 0,
        maximumDiscountValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));

    setShouldRetrieveRouteData(true);
  };

  const onDiscountChanged = (discountValue: number) => {
    setBillingFormData((current) => ({ ...current!, discount: discountValue }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    setBillingFormData(billingData);
    setBillingData(billingData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getCargoTaxiAddBillingsTranslations();

  const messengerChannelsAvailability =
    billingsCargoTaxiHelper.getMessengerChannelAvailability();

  const validateContributionType = (
    type: BillingsCargoTaxiContributionDiscountType | null,
    position: number
  ) => {
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateType(type);

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,
      discount: contributionsValidationResults.discount.map((validation) =>
        validation.position === position
          ? {
              ...validation,
              type: validationResult,
            }
          : validation
      ),
    };
    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionPercentage = (
    percentage: number | null,
    position: number
  ) => {
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validatePercentage(
        percentage,
        billingFormData?.contractDetails?.discountLimit
      );

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,

      discount: contributionsValidationResults.discount.map((validation) =>
        validation.position === position
          ? {
              ...validation,
              percentage: validationResult,
            }
          : validation
      ),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionAmount = (
    amount: number | null,
    position: number
  ) => {
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateAmount(
        amount
      );

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,
      discount: contributionsValidationResults.discount.map((validation) =>
        validation.position === position
          ? {
              ...validation,
              amount: validationResult,
            }
          : validation
      ),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionComment = (
    comment: string | null,
    position: number
  ) => {
    const validationResult =
      billingCargoTaxiContributionsDiscountValidationService.validateComment(
        comment ?? ""
      );

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,
      discount: contributionsValidationResults.discount.map((validation) =>
        validation.position === position
          ? {
              ...validation,
              comment: validationResult,
            }
          : validation
      ),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const onContributionTypeChange = (
    position: number,
    type: BillingsCargoTaxiContributionDiscountType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: billingFormData!.billingContributions.discount.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  type,
                } as BillingsCargoTaxiContributions)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
    recalculateBilling(formData);
  };

  const onContributionPercentageChange = (
    position: number,
    percentage: number | null
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: billingFormData!.billingContributions.discount.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  percentage,
                } as BillingsCargoTaxiContributions)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionAmountChange = (
    position: number,
    amount: number | null
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: billingFormData!.billingContributions.discount.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  amount,
                } as BillingsCargoTaxiContributions)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionCommentChange = (position: number, comment: string) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: billingFormData!.billingContributions.discount.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  comment,
                } as BillingsCargoTaxiContributions)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionTypeBlur = (position: number) => {
    const contributions = billingsCargoTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions
    );

    const type = contributions[position].type;

    if (!type) {
      deleteContribution(position, false);
    } else {
      validateContributionType(type, position);
    }
  };

  const onContributionPercentageBlur = (position: number) => {
    const percentage = (
      billingFormData!.billingContributions.discount[
        position
      ] as BillingsCargoTaxiContributionContractCorretionPercentage
    ).percentage;

    const isPerentageValid = validateContributionPercentage(
      percentage,
      position
    );

    if (isPerentageValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionAmountBlur = (position: number) => {
    const contributions = billingsCargoTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions
    );

    const amount = (
      contributions[position] as
        | BillingsCargoTaxiContributionDiscountOptimization
        | BillingsCargoTaxiContributionDiscountBeingLate
        | BillingsCargoTaxiContributionDiscountIncompatibleCar
        | BillingsCargoTaxiContributionDiscountOther
    ).amount;

    const isAmountValid = validateContributionAmount(amount, position);

    if (isAmountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionCommentBlur = (position: number) => {
    const contributions = billingsCargoTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions
    );

    const comment = (
      contributions[position] as
        | BillingsCargoTaxiContributionDiscountOptimization
        | BillingsCargoTaxiContributionDiscountBeingLate
        | BillingsCargoTaxiContributionDiscountIncompatibleCar
        | BillingsCargoTaxiContributionDiscountOther
    ).comment;

    const isCommentValid = validateContributionComment(comment, position);

    if (isCommentValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateContributions = () => {
    const contributionsValidation: BillingContributionValidationResult = {
      discount: billingFormData!.billingContributions.discount.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE: {
              return {
                position: index,
                comment: formValidationService.defaultValidationResult,
                amount: formValidationService.defaultValidationResult,
                type: billingCargoTaxiContributionsDiscountValidationService.validateType(
                  contribution.type
                ),
                percentage:
                  billingCargoTaxiContributionsDiscountValidationService.validatePercentage(
                    contribution.percentage
                  ),
              };
            }
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
            case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER: {
              return {
                position: index,
                comment:
                  billingCargoTaxiContributionsDiscountValidationService.validateComment(
                    contribution.comment
                  ),
                amount:
                  billingCargoTaxiContributionsDiscountValidationService.validateAmount(
                    contribution.amount
                  ),
                type: billingCargoTaxiContributionsDiscountValidationService.validateType(
                  contribution.type
                ),
                percentage: formValidationService.defaultValidationResult,
              };
            }
            default: {
              return {
                position: index,
                type: formValidationService.defaultValidationResult,
                percentage: formValidationService.defaultValidationResult,
                amount: formValidationService.defaultValidationResult,
                comment: formValidationService.defaultValidationResult,
              };
            }
          }
        }
      ),
    };

    setContributionsValidationResults(contributionsValidation);

    return contributionsValidation.discount.every(
      (validation) =>
        validation.amount.isValid &&
        validation.comment.isValid &&
        validation.percentage.isValid &&
        validation.type.isValid
    );
  };

  const deleteContribution = (
    position: number,
    recalculate: boolean = true
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: billingFormData!.billingContributions.discount.filter(
          (contribution, index) => index !== position
        ),
      },
    };

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,
      discount: contributionsValidationResults.discount
        .filter((validation) => validation.position !== position)
        .map((validation, index) => ({ ...validation, position: index })),
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);

    if (recalculate) {
      deleteContributionRecalculateBilling(formData);
    }
  };

  const addNewContribution = () => {
    const newContribution = billingsCargoTaxiHelper.getNewContribution();

    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        discount: [
          ...billingFormData!.billingContributions.discount,
          newContribution as BillingsCargoTaxiContributions,
        ],
      },
    };

    const validationResults: BillingContributionValidationResult = {
      ...contributionsValidationResults,
      discount: [
        ...contributionsValidationResults.discount,
        {
          position: contributionsValidationResults.discount.length,
          type: formValidationService.defaultValidationResult,
          percentage: formValidationService.defaultValidationResult,
          amount: formValidationService.defaultValidationResult,
          comment: formValidationService.defaultValidationResult,
        },
      ],
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);
  };

  const tabsData: TabsData = useMemo(() => {
    if (!billingFormData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingFormData.billingContributions,
      isBillingDataLoading,
      deleteContribution,
      contributionsValidationResults,
      onContributionTypeChange,
      onContributionAmountChange,
      onContributionCommentChange,
      onContributionPercentageChange,
      onContributionTypeBlur,
      onContributionPercentageBlur,
      onContributionAmountBlur,
      onContributionCommentBlur
    );
  }, [billingFormData, contributionsValidationResults, selectedAppLanguage]);

  const tabMaxContributions = useMemo(
    () => billingsCargoTaxiHelper.getMaxContributionsFromTabIndex(),
    [activeTab]
  );

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    setShouldShowOrderHistory(true);
  };

  const billingsCargoTaxiToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace(
            "#{orderInternalId}",
            orderInternalId
          )}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                  <BillingsToolsComponent {...billingsCargoTaxiToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      orderId={orderUuid!}
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent
                      billingData={billingFormData}
                      onDiscountChanged={onDiscountChanged}
                      onDiscountBlur={onDiscountBlur}
                      formValidationResults={formValidationResults}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingData={billingData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent
                    data={tabsData}
                    onActiveTabChange={setActiveTab}
                  />
                  <div className="billings_contribution_add_button">
                    <ButtonComponent
                      onClick={addNewContribution}
                      type="success"
                      title={translations.contributionAddButtonTitle}
                      isDisabled={
                        tabsData[activeTab].totalTableRows >=
                        tabMaxContributions
                      }
                    >
                      {translations.contributionAddButtonLabel}
                    </ButtonComponent>
                  </div>
                </Column>
              )}
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} />
                </CardComponent>
              </Column>
              <Column>
                <ButtonComponent type="primary" onClick={openModal}>
                  {translations.submitLabel}
                </ButtonComponent>
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={billingData?.planEntryId}
                  orderId={orderUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent orderId={orderUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            {!!billingData && (
              <CardComponent classNames={{ root: "billings_history_messenger_container", content: "h-100" }}>
                {shouldShowOrderHistory ? (
                  <OrderDetailsHistoryComponent
                    orderUuid={orderUuid!}
                    refetchFlag={false}
                  />
                ) : (
                  <MessengerComponent
                    channelsAvailability={messengerChannelsAvailability}
                    billingType={MessengerBillingType.CARGO}
                    orderUuid={orderUuid}
                    planEntryUuid={billingData.planEntryId}
                  />
                )}
              </CardComponent>
            )}
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsCargoTaxiAddComponent;
