import BillingsCargoTaxiContributions, {
  BillingsCargoTaxiContributionDiscountType,
} from "../../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingSaveRequest, {
  BillingSaveRequestContributionType,
  BillingSaveRequestDiscountContribution,
} from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createSaveContributionType = (
  contributionType:
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION
    | BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER
):
  | BillingSaveRequestContributionType.DISCOUNT_BEING_LATE
  | BillingSaveRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR
  | BillingSaveRequestContributionType.DISCOUNT_OPTIMIZATION
  | BillingSaveRequestContributionType.DISCOUNT_OTHER => {
  switch (contributionType) {
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
      return BillingSaveRequestContributionType.DISCOUNT_BEING_LATE;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
      return BillingSaveRequestContributionType.DISCOUNT_INCOMPATIBLE_CAR;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
      return BillingSaveRequestContributionType.DISCOUNT_OPTIMIZATION;
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return BillingSaveRequestContributionType.DISCOUNT_OTHER;
  }
};

const createBillingDiscountContribution = (
  contribution: BillingsCargoTaxiContributions
): BillingSaveRequestDiscountContribution | undefined => {
  switch (contribution.type) {
    case BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE:
      return {
        type: BillingSaveRequestContributionType.CONTRACT_CORRECTION_PERCENTAGE,
        percentage: contribution.percentage ?? 0,
        amount: contribution.amount ?? 0,
      };
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION:
    case BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER:
      return {
        type: createSaveContributionType(contribution.type),
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingContributions = (
  contributions: BillingFormDataContributions
): BillingSaveRequestDiscountContribution[] => {
  const discountContributions = contributions.discount.map(
    (contribution) => createBillingDiscountContribution(contribution)!
  );

  return [...discountContributions];
};

const createSaveBillingRequest = (
  data: BillingFormData
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: data.billingNodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate!,
        checkout_event_id: specificNode.checkoutEventUuid,
        planned_date: specificNode.plannedDate!,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    billing_contributions: createBillingContributions(
      data.billingContributions
    ),
    discount: 0,
  };
  return result;
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
