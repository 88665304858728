import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: true,
    [MessengerChannel.CARGO]: true,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: true,
    [MessengerChannel.SHARED]: true,
    [MessengerChannel.TAXI]: true,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const billingsCargoTaxiHelper = {
  getMessengerChannelAvailability,
};

export default billingsCargoTaxiHelper;
