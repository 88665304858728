import { FC } from "react";
import TaxiOrderSettlementListingStatsSummary from "../types/taxi-order-settlement-listing-stats-summary";
import TableComponent from "../../../../../../common/components/table/table.component";
import TaxiOrderSettlementListingStatsSummaryTableRow from "../types/taxi-order-settlement-listing-stats-summary-table-row";
import taxiOrderSettlementListingStatsSummaryHelper from "./taxi-order-settlement-listing-stats-summary.helper";
import CardComponent from "../../../../../../common/components/card/card.component";
import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";

type TaxiOrderSettlementListingStatsSummaryProps = {
  isError: boolean;
  isLoading: boolean;
  statsSummary: TaxiOrderSettlementListingStatsSummary | null;
};

const TaxiOrderSettlementListingStatsSummaryComponent: FC<
  TaxiOrderSettlementListingStatsSummaryProps
> = (props) => {
  if (!props.statsSummary) {
    return null;
  }

  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .stats;

  const createTableRow = (
    statsSummary: TaxiOrderSettlementListingStatsSummary
  ): TaxiOrderSettlementListingStatsSummaryTableRow => {
    return {
      id: "stats-summary-row",
      idForTesting: "stats-summary-row",
      value: {
        distance: (
          <div title={String(statsSummary.distance)}>
            {statsSummary.distance &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.distance
              ) + " KM"}
          </div>
        ),
        haltingCost: (
          <div title={String(statsSummary.haltingCost)}>
            {statsSummary.haltingCost &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.haltingCost
              ) + " ZŁ"}
          </div>
        ),
        highwayCost: (
          <div title={String(statsSummary.highwayCost)}>
            {statsSummary.highwayCost &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.highwayCost
              ) + " ZŁ"}
          </div>
        ),
        distanceCost: (
          <div title={String(statsSummary.distanceCost)}>
            {statsSummary.distanceCost &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.distanceCost
              ) + " ZŁ"}
          </div>
        ),
        sumOfDiscounts: (
          <div title={String(statsSummary.sumOfDiscounts)}>
            {statsSummary.sumOfDiscounts &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.sumOfDiscounts
              ) + " ZŁ"}
          </div>
        ),
        total: (
          <div title={String(statsSummary.total)}>
            {statsSummary.total &&
              taxiOrderSettlementListingStatsSummaryHelper.formatNumber(
                statsSummary.total
              ) + " ZŁ"}
          </div>
        ),
      },
    };
  };

  const columns = taxiOrderSettlementListingStatsSummaryHelper.getColumns();
  const rows = [createTableRow(props.statsSummary)];

  return (
    <CardComponent
      classNames={{ root: "mt-4" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent
        columns={columns}
        isError={props.isError}
        isLoading={props.isLoading}
        rows={rows}
      />
    </CardComponent>
  );
};

export default TaxiOrderSettlementListingStatsSummaryComponent;
