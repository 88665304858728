import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";
import BillingNode from "./types/billing-node";

export enum BillingSaveRequestContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
  PENALTY_WRONG_ROUTE = "PENALTY_WRONG_ROUTE",
  PENALTY_BEING_LATE = "PENALTY_BEING_LATE",
  PENALTY_INCOMPATIBLE_CAR = "PENALTY_INCOMPATIBLE_CAR",
  PENALTY_OTHER = "PENALTY_OTHER",
}

export type BillingSaveRequestBonusContributionFavorableDistance = {
  type: BillingSaveRequestContributionType.BONUS_FAVORABLE_DISTANCE;
  comment: string | null;
  distance: number;
  rate: number;
};

export type BillingSaveRequestBonusContributionOther = {
  type: BillingSaveRequestContributionType.BONUS_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionWrongRoute = {
  type: BillingSaveRequestContributionType.PENALTY_WRONG_ROUTE;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionBeingLate = {
  type: BillingSaveRequestContributionType.PENALTY_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionIncompatibleCar = {
  type: BillingSaveRequestContributionType.PENALTY_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestPenaltyContributionOther = {
  type: BillingSaveRequestContributionType.PENALTY_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingSaveRequestBonusContribution =
  | BillingSaveRequestBonusContributionFavorableDistance
  | BillingSaveRequestBonusContributionOther;

export type BillingSaveRequestPenaltyContribution =
  | BillingSaveRequestPenaltyContributionWrongRoute
  | BillingSaveRequestPenaltyContributionBeingLate
  | BillingSaveRequestPenaltyContributionIncompatibleCar
  | BillingSaveRequestPenaltyContributionOther;

export type BillingSaveRequestContribution =
  | BillingSaveRequestBonusContribution
  | BillingSaveRequestPenaltyContribution;

type BillingSaveRequest = {
  billing_nodes: BillingNode[];
  billing_contributions: BillingSaveRequestContribution[];
  billing_type: string;
  bonus: number;
  bonus_type: BonusType | undefined;
  penalty: number;
  penalty_type: PenaltyType | undefined;
};

export default BillingSaveRequest;
