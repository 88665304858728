import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingRecalculateRequest, {
  BillingRecalculateRequestBonusContribution,
  BillingRecalculateRequestContractCorrectionContribution,
  BillingRecalculateRequestContribution,
  BillingRecalculateRequestContributionType,
  BillingRecalculateRequestPenaltyContribution,
} from "../api/billing-recalculate.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData, {
  BillingFormDataContributions,
} from "../types/billing-form.data";

const createBillingBonusContribution = (
  contribution: BillingsTaxiDriverContributionBonus
): BillingRecalculateRequestBonusContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE:
      return {
        type: BillingRecalculateRequestContributionType.BONUS_FAVORABLE_DISTANCE,
        comment: contribution.comment ?? "",
        distance: contribution.distance ?? 0,
        rate: contribution.rate ?? 0,
      };
    case BillingsTaxiDriverContributionBonusType.BONUS_OTHER:
      return {
        type: BillingRecalculateRequestContributionType.BONUS_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingPenaltyContribution = (
  contribution: BillingsTaxiDriverContributionPenalty
): BillingRecalculateRequestPenaltyContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_BEING_LATE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_INCOMPATIBLE_CAR,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_OTHER,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE:
      return {
        type: BillingRecalculateRequestContributionType.PENALTY_WRONG_ROUTE,
        comment: contribution.comment ?? "",
        amount: contribution.amount ?? 0,
      };
  }
};

const createBillingContractCorrectionContribution = (
  contribution: BillingsTaxiDriverContributionContractCorrection,
  distance: number
): BillingRecalculateRequestContractCorrectionContribution | undefined => {
  switch (contribution.type) {
    case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI:
      return {
        type: BillingRecalculateRequestContributionType.BASE_AMOUNT_EXTERNAL_TAXI,
        distance: distance ?? 0,
        amount: contribution.amount ?? 0,
      };
    case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE:
      return {
        type: BillingRecalculateRequestContributionType.CONTRACT_CORRECTION_RATE,
        rate: contribution.rate ?? 0,
      };
    default:
      return undefined;
  }
};

const createBillingContributions = (
  contributions: BillingFormDataContributions,
  distance: number
): BillingRecalculateRequestContribution[] => {
  const bonusContributions: BillingRecalculateRequestBonusContribution[] =
    contributions.bonus
      .filter((contribution) => contribution.type !== null)
      .map((contribution) => createBillingBonusContribution(contribution)!);

  const penaltyContributions: BillingRecalculateRequestPenaltyContribution[] =
    contributions.penalty
      .filter((contribution) => contribution.type !== null)
      .map((contribution) => createBillingPenaltyContribution(contribution)!);

  const contractCorrectionContributions: BillingRecalculateRequestContractCorrectionContribution[] =
    contributions.contractCorrection
      .filter((contribution) => contribution.type !== null)
      .map(
        (contribution) =>
          createBillingContractCorrectionContribution(contribution, distance)!
      );

  return [
    ...bonusContributions,
    ...penaltyContributions,
    ...contractCorrectionContributions,
  ];
};

const createRecalculateRequest = (
  data: BillingFormData
): BillingRecalculateRequest => {
  const result: BillingRecalculateRequest = {
    billing_nodes: createBilligNodes(data),
    billing_contributions: createBillingContributions(
      data.billingContributions,
      data.distance
    ),
    bonus: 0,
    penalty: 0,
    bonus_type: data.bonusType,
    penalty_type: data.penaltyType,
    billing_type: "TAXI_2_DRIVER",
  };
  return result;
};

const createBilligNodes = (data: BillingFormData): BillingNodeApi[] => {
  return data.billingNodes.map((specificNode) => {
    const node: BillingNodeApi = {
      id: specificNode.id,
      node_id: specificNode.nodeId,
      order_id: specificNode.orderId,
      lat: specificNode.lat,
      lon: specificNode.lon,
      distance: specificNode.distance,
      description: specificNode.description,
      checkout_date: specificNode.checkoutDate!,
      checkout_event_id: specificNode.checkoutEventUuid,
      planned_date: specificNode.plannedDate!,
      halting_amount: specificNode.haltingAmount,
      halting_time: specificNode.haltingTime,
      allow_charge: specificNode.isHighwayAllowed,
      highway_charge: specificNode.highwayCharge,
      planned_distance: specificNode.plannedDistance,
      editable_coordinates: specificNode.isEditable,
    };
    return node;
  });
};

const billingRecalculateRequestFactory = {
  createRecalculateRequest,
};

export default billingRecalculateRequestFactory;
