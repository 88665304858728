import BillingModel from "../../../common/types/billing-model";
import {
  TaxiRouteSettlementListingResponseDataItem,
  TaxiRouteSettlementListingResponseDataItemBillingNodes,
  TaxiRouteSettlementListingResponseDataStats,
} from "./api/taxi-route-settlement-listing.response";
import TaxiRouteSettlementListingItem from "./types/taxi-route-settlement-listing-item";
import TaxiRouteSettlementListingStatsSummary from "./types/taxi-route-settlement-listing-stats-summary";

const createListingItemRouteAddress = (
  nodes: TaxiRouteSettlementListingResponseDataItemBillingNodes[]
): string => {
  return nodes.map((item) => item.description).join(", ");
};

const createListingItem = (
  responseDataItem: TaxiRouteSettlementListingResponseDataItem
): TaxiRouteSettlementListingItem => {
  return {
    uuid: responseDataItem.id,
    routeId: responseDataItem.human_id,
    cargoOrderInternalIds: responseDataItem.cargo_order_human_ids.join(", "),
    routeStartDate: new Date(responseDataItem.date),
    billingModel: responseDataItem.billing_model as BillingModel,
    sumOfBonuses: responseDataItem.sum_of_bonuses ?? 0,
    sumOfPenalties: responseDataItem.sum_of_penalties ?? 0,
    distance: responseDataItem.distance,
    additionalDistance: responseDataItem.favorable_distance,
    distanceCost: responseDataItem.amount_for_distance ?? 0,
    distanceRate: responseDataItem.distance_rate,
    calculatedDistanceRate: responseDataItem.rate_for_distance,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    totalCost: responseDataItem.all_contributions_amount ?? 0,
    driverName: responseDataItem.driver,
    fleetPartner: responseDataItem.fleet_partner,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    addresses: createListingItemRouteAddress(responseDataItem.billing_nodes),
  };
};

const createListingItems = (
  responseDataItems: TaxiRouteSettlementListingResponseDataItem[]
): TaxiRouteSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: TaxiRouteSettlementListingResponseDataStats
): TaxiRouteSettlementListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    haltingCost: responseStatsSummary.amount_for_charge_haltings,
    highwayCost: responseStatsSummary.amount_for_charge_highways,
    total: responseStatsSummary.all_contributions_amount,
    sumOfBonuses: responseStatsSummary.sum_of_bonuses,
    sumOfPenalties: responseStatsSummary.sum_of_penalties,
  };
};

const taxiRouteSettlementListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default taxiRouteSettlementListingFactory;
