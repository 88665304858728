import { FC } from "react";
import BillingFormData from "../types/billing-form.data";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import CardComponent from "../../../../../common/components/card/card.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";

type AdditionalOptionsProps = {
  billingData: BillingFormData | undefined;
};

const AdditionalOptionsComponent: FC<AdditionalOptionsProps> = (props) => {
  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations()
      .additionalOptions;

  return (
    <>
      <CardComponent classNames={{ root: "h-100 " }}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.discountLabel}
              classNames={{ root: `mt-0` }}
            >
              {props.billingData?.discount} PLN
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
    </>
  );
};
export default AdditionalOptionsComponent;
