import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiRouteSettlementListingStatsSummaryTableColumn from "../types/taxi-route-settlement-listing-stats-summary-table-column";

const getColumns = (): TaxiRouteSettlementListingStatsSummaryTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .stats.table.headers;

  const tableColumns: TaxiRouteSettlementListingStatsSummaryTableColumn[] = [
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.haltingCostLabel,
      title: translations.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 10,
    },
    {
      header: translations.highwayCostLabel,
      title: translations.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 10,
    },
    {
      header: translations.sumOfBonusesLabel,
      title: translations.sumOfBonusesTitle,
      accessor: "sumOfBonuses",
      colSpan: 10,
    },
    {
      header: translations.sumOfPenaltiesLabel,
      title: translations.sumOfPenaltiesTitle,
      accessor: "sumOfPenalties",
      colSpan: 10,
    },
    {
      header: translations.totalLabel,
      title: translations.totalTitle,
      accessor: "total",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const formatNumber = (value: number) => {
  return (+value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const taxiRouteSettlementListingStatsSummaryHelper = {
  formatNumber,
  getColumns,
};

export default taxiRouteSettlementListingStatsSummaryHelper;
