import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardComponent from "../../../common/components/card/card.component";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import LoaderComponent from "../../../common/components/loader/loader.component";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import OrderEditRouteParams from "../common/routes/types/order-edit-route-params";
import orderRouteEditApiService from "./common/api/order-route-edit-api.service";
import { OrderRouteEditOrderDetailsData } from "./common/api/order-route-edit-order-details.response";
import OrderRouteEditPassengersComponent from "./common/components/passengers/order-route-edit-passengers.component";
import OrderRouteEditRouteValidationModalComponent from "./common/components/route-validation-modal/order-route-edit-route-validation-modal.component";
import OrderRouteEditRoutesComponent from "./common/components/routes/order-route-edit-routes.component";
import OrderRouteEditSummaryComponent from "./common/components/summary/order-route-edit-summary.component";
import orderRouteEditFactory from "./common/factory/order-route-edit.factory";
import OrderRouteEditOrderDetails from "./common/types/order-route-edit-order-details";
import OrderRouteEditPassenger from "./common/types/order-route-edit-passenger";
import OrderRouteEditPassengerListItem from "./common/types/order-route-edit-passenger-list-item";
import OrderRouteEditRouteItem from "./common/types/order-route-edit-route-waypoint";
import OrderRouteEditTaxiContract from "./common/types/order-route-edit-taxi-contract";
import orderRouteEditValidationService from "./common/validation/order-route-edit-validation.service";
import OrderRouteEditRequestBody from "./common/api/order-route-edit.request";
import orderRoutesHelper from "../common/routes/order-routes.helper";
import OrderRouteEditConfirmationModalComponent from "./common/components/confirmation-modal/order-route-edit-confirmation-modal.component";
import OrderRouteEditResponse, {
  OrderRouteEditNotAcceptableResponse,
  OrderRouteEditNotAcceptableResponseError,
  OrderRouteEditUnprocessableEntityTimeError,
} from "./common/api/order-route-edit.response";
import useOpen from "../../../common/hooks/use-open";
import orderRouteEditHelper from "./common/helper/order-route-edit.helper";

type OrderRouteEditProps = {};

const OrderRouteEditComponent: FC<OrderRouteEditProps> = () => {
  const routeParams = useParams<OrderEditRouteParams>();

  const translations = orderTranslationsHelper.getEditTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderRouteEdit);

  const { setBreadcrumbs, selectedAppLanguage, setAreBreadcrumbsLoading } =
    useAppContext();

  const [passengerList, setPassengerList] = useState<
    OrderRouteEditPassengerListItem[]
  >([]);

  const [routes, setRoutes] = useState<OrderRouteEditRouteItem[]>([]);

  const navigate = useNavigate();

  const [selectedPassengerUuid, setSelectedPassengerUuid] = useState<
    OrderRouteEditPassenger["uuid"] | null
  >(null);

  const [isEditOrderFetching, setIsEditOrderFetching] = useState(false);

  const { isOpen, open, close } = useOpen();

  const [routesValidationErrors, setRoutesValidationErrors] = useState<
    string[]
  >([]);

  const [taxiContract, setTaxiContract] = useState<OrderRouteEditTaxiContract>({
    contractPermitsTollRoads: false,
  });

  const [isRouteErrorsModalOpen, setIsRouteErrorsModalOpen] = useState(false);

  const [prevOrderDetailsData, setPrevOrderDetailsData] =
    useState<OrderRouteEditOrderDetailsData | null>(null);

  const [orderDetails, setOrderDetails] =
    useState<OrderRouteEditOrderDetails | null>(null);

  useEffect(() => {
    const fetchPrevOrderDetails = async () => {
      setAreBreadcrumbsLoading(true);

      const response = await orderRouteEditApiService.fetchOrderDetails(
        routeParams.orderId!
      );
      if (response.status === 200) {
        setPrevOrderDetailsData(response.data);
      }
    };

    fetchPrevOrderDetails();
  }, []);

  useEffect(() => {
    if (prevOrderDetailsData) {
      const orderDetails =
        orderRouteEditFactory.createOrderDetails(prevOrderDetailsData);
      setOrderDetails(orderDetails);
      setAreBreadcrumbsLoading(false);

      const prevPassengers =
        orderRouteEditFactory.createPassengersList(prevOrderDetailsData);
      setPassengerList(prevPassengers);

      const prevRoutes = orderRouteEditFactory.createRouteItems(
        prevOrderDetailsData,
        prevPassengers
      );
      setRoutes(prevRoutes);
    }
  }, [prevOrderDetailsData]);

  useEffect(() => {
    if (orderDetails) {
      const breadcrumbs = orderBreadcrumbsHelper.getEditBreadcrumbs({
        cargoInternalOrderId: orderDetails.humanId,
        orderUuid: routeParams.orderId!,
      });

      setBreadcrumbs(breadcrumbs);
    }
  }, [selectedAppLanguage, orderDetails]);

  useEffect(() => {
    if (!orderDetails) {
      return;
    }

    const fetchTaxiContracts = async () => {
      const dispatcherId = orderDetails.dispatcherId;
      const response = await orderRouteEditApiService.fetchTaxiContracts(
        dispatcherId
      );
      if (response.status === 200) {
        const taxiContract = orderRouteEditFactory.createTaxiContract(
          response.data,
          orderDetails.taxiCorporationId
        );
        setTaxiContract(taxiContract);
      }
    };

    fetchTaxiContracts();
  }, [orderDetails]);

  useEffect(() => {
    if (!routesValidationErrors.length) {
      return;
    }

    setIsRouteErrorsModalOpen(true);
  }, [routesValidationErrors]);

  const isAnyDateFromRouteItemsPastDate = useMemo(
    () => orderRouteEditHelper.checkIsRouteItemsPastDate(routes),
    [routes]
  );

  const restoreForm = () => {
    setPassengerList([]);
    setRoutes([]);
    setRoutesValidationErrors([]);
  };

  const validateRoutes = (): boolean => {
    const validationErrors = orderRouteEditValidationService.validateRoutes(
      passengerList,
      routes
    );

    const resolvedErrors =
      orderRouteEditValidationService.resolveValidationErrorLabels(
        validationErrors
      );

    setRoutesValidationErrors(resolvedErrors);

    return !validationErrors.length;
  };

  const onEditClick = () => {
    if (
      isAnyDateFromRouteItemsPastDate
      // TODO: Feature #948 postponed to next release
      // !!!taxiContract.contractPermitsTollRoads
    ) {
      open();

      return;
    }

    onSubmitButtonClick();
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      close();
      return;
    }

    const request: OrderRouteEditRequestBody =
      orderRouteEditFactory.createEditRequest(
        prevOrderDetailsData!,
        routes,
        passengerList
      );

    setIsEditOrderFetching(true);

    orderRouteEditApiService
      .editOrder(prevOrderDetailsData!.id, request)
      .then(handleOrderRouteEditResponse)
      .finally(() => {
        setIsEditOrderFetching(false);
        close();
      });
  };

  const handleOrderRouteEditResponse = (response: OrderRouteEditResponse) => {
    if (response.status === 200) {
      navigate(
        orderRoutesHelper.getListingOfActiveOrdersRoute({
          defaultSelectedOrderUuid: routeParams.orderId,
        })
      );
      return;
    }

    if (response.status === 406) {
      const errorResponse = response as OrderRouteEditNotAcceptableResponse;
      onApiError(
        errorResponse.data as OrderRouteEditNotAcceptableResponseError
      );
    }

    if (response.status === 422) {
      onUnprocessableEntityError(
        response.data as OrderRouteEditUnprocessableEntityTimeError
      );
    }
  };

  const validateForm = (): boolean => {
    return validateRoutes();
  };

  const onApiError = (error: OrderRouteEditNotAcceptableResponseError) => {
    const resolvedApiError =
      orderRouteEditApiService.resolveApiErrorLabel(error);

    setRoutesValidationErrors([resolvedApiError]);
  };

  const onUnprocessableEntityError = (
    error: OrderRouteEditUnprocessableEntityTimeError
  ) => {
    const resolvedUnprocessableEntityError =
      orderRouteEditApiService.resolveUnprocessableEntityError(error);

    setRoutesValidationErrors([resolvedUnprocessableEntityError]);
  };

  return (
    <>
      <div className="order_edit">
        <HeadingComponent title={translations.header.headingText} />
        <Row>
          <Column xl={7}>
            <CardComponent header={{ title: translations.order.headingText }}>
              <Row>
                {orderDetails ? (
                  <>
                    <Column lg={4}>
                      <OrderRouteEditPassengersComponent
                        onPassengerListChange={setPassengerList}
                        passengerList={passengerList}
                        onSelectedPassengerUuidChange={setSelectedPassengerUuid}
                        contractorUuid={orderDetails.cargoCompanyId}
                        selectedPassengerUuid={selectedPassengerUuid}
                      />
                    </Column>
                    <Column lg={8}>
                      <OrderRouteEditRoutesComponent
                        onRoutesChange={setRoutes}
                        passengerList={passengerList}
                        routes={routes}
                        contractorUuid={orderDetails.cargoCompanyId}
                      />
                    </Column>
                  </>
                ) : (
                  <div className="order_edit__loading_wrapper">
                    <LoaderComponent type="primary" />
                  </div>
                )}
              </Row>
            </CardComponent>
          </Column>
          <Column xl={5}>
            <OrderRouteEditSummaryComponent
              cargoCompanyOrderId={orderDetails?.humanId}
              orderId={routeParams.orderId}
              passengerList={passengerList}
              routes={routes}
              taxiContract={taxiContract}
              restoreForm={restoreForm}
              validateForm={validateForm}
              selectedPassengerUuid={selectedPassengerUuid}
              onSubmitButtonClick={onEditClick}
              isEditOrderFetching={isEditOrderFetching}
              excludeHighway={!!!orderDetails?.forceAllowToll}
            />
          </Column>
        </Row>
      </div>
      <OrderRouteEditRouteValidationModalComponent
        isModalOpen={isRouteErrorsModalOpen}
        onCloseClick={() => {
          setIsRouteErrorsModalOpen(false);
        }}
        routesValidationErrors={routesValidationErrors}
      />
      <OrderRouteEditConfirmationModalComponent
        isAnyDateFromRouteItemsPastDate={isAnyDateFromRouteItemsPastDate}
        // TODO: Feature #948 postponed to next release
        // isTaxiContractPermitsTollRoads={!!orderDetails?.forceAllowToll}
        isModalOpen={isOpen}
        onCloseClick={close}
        onSubmitClick={onSubmitButtonClick}
        isEditOrderFetching={isEditOrderFetching}
      />
    </>
  );
};

export default OrderRouteEditComponent;
