import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsTaxiDriverContributionsBonusHelper from "../common/contributions/bonus/billings-taxi-driver-contributions-bonus.helper";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusType,
} from "../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import billingsTaxiDriverContributionsContractCorrectionHelper from "../common/contributions/contract-correction/billings-taxi-driver-contributions-contract-correction.helper";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import billingsTaxiDriverContributionsPenaltyHelper from "../common/contributions/penalty/billings-taxi-driver-contributions-penalty.helper";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingContributionType from "./types/billing-contribution-type";
import BillingContributionTypeSelectOption from "./types/billing-contribution-type-select-option";
import { BillingFormDataContributions } from "./types/billing-form.data";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: false,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: false,
    [MessengerChannel.SHARED]: false,
    [MessengerChannel.TAXI]: false,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const getContributionsBonusTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

    return [
      {
        label: translations.bonus.type.favorableDistance,
        value: BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE,
      },
      {
        label: translations.bonus.type.other,
        value: BillingsTaxiDriverContributionBonusType.BONUS_OTHER,
      },
    ];
  };

const getContributionsPenaltyTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

    return [
      {
        label: translations.penalty.type.beingLate,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_BEING_LATE,
      },
      {
        label: translations.penalty.type.incompatibleCar,
        value:
          BillingsTaxiDriverContributionPenaltyType.PENALTY_INCOMPATIBLE_CAR,
      },
      {
        label: translations.penalty.type.other,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_OTHER,
      },
      {
        label: translations.penalty.type.wrongRoute,
        value: BillingsTaxiDriverContributionPenaltyType.PENALTY_WRONG_ROUTE,
      },
    ];
  };

const getContributionsContractCorrectionTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiDriverContributionsTranslations();

    return [
      {
        label: translations.contractCorrection.type.baseAmountExternalTaxi,
        value:
          BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI,
      },
      {
        label: translations.contractCorrection.type.otherRate,
        value:
          BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE,
      },
    ];
  };

const getContributionTypeFromTabIndex = (
  tabIndex: number
): BillingContributionType | undefined => {
  switch (tabIndex) {
    case 0:
      return BillingContributionType.BONUS;
    case 1:
      return BillingContributionType.PENALTY;
    case 2:
      return BillingContributionType.CONTRACT_CORRECTION;
    default:
      return undefined;
  }
};

const getMaxContributionsFromTabIndex = (tabIndex: number): number => {
  const contributionType: BillingContributionType | undefined =
    getContributionTypeFromTabIndex(tabIndex);

  switch (contributionType) {
    case BillingContributionType.BONUS:
      return Object.keys(BillingsTaxiDriverContributionBonusType).length;
    case BillingContributionType.PENALTY:
      return Object.keys(BillingsTaxiDriverContributionPenaltyType).length;
    case BillingContributionType.CONTRACT_CORRECTION:
      return Object.keys(BillingsTaxiDriverContributionContractCorrectionType)
        .length;
    default:
      return 0;
  }
};

const getNewContribution = (
  type: BillingContributionType,
  distance: number
):
  | BillingsTaxiDriverContributionBonus
  | BillingsTaxiDriverContributionPenalty
  | BillingsTaxiDriverContributionContractCorrection => {
  switch (type) {
    case BillingContributionType.BONUS:
      return billingsTaxiDriverContributionsBonusHelper.getBonusNewContribution();
    case BillingContributionType.PENALTY:
      return billingsTaxiDriverContributionsPenaltyHelper.getNewPenaltyContribution();
    case BillingContributionType.CONTRACT_CORRECTION:
      return billingsTaxiDriverContributionsContractCorrectionHelper.getNewContractCorrectionContribution(
        distance
      );
  }
};

const getContributionsByType = (
  contributions: BillingFormDataContributions,
  type: BillingContributionType
):
  | BillingsTaxiDriverContributionBonus[]
  | BillingsTaxiDriverContributionPenalty[]
  | BillingsTaxiDriverContributionContractCorrection[] => {
  switch (type) {
    case BillingContributionType.BONUS:
      return contributions.bonus;
    case BillingContributionType.PENALTY:
      return contributions.penalty;
    case BillingContributionType.CONTRACT_CORRECTION:
      return contributions.contractCorrection;
  }
};

const billingsTaxiDriverHelper = {
  getMessengerChannelAvailability,
  getContributionsBonusTypeSelectOptions,
  getContributionsPenaltyTypeSelectOptions,
  getContributionsContractCorrectionTypeSelectOptions,
  getContributionTypeFromTabIndex,
  getMaxContributionsFromTabIndex,
  getNewContribution,
  getContributionsByType,
};

export default billingsTaxiDriverHelper;
