import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsCargoTaxiContributionsDiscountHelper from "../common/contributions/discount/billings-cargo-taxi-contributions-discount.helper";
import BillingsCargoTaxiContributions, {
  BillingsCargoTaxiContributionDiscountType,
} from "../common/contributions/discount/types/billings-cargo-taxi-contributions-discount";
import BillingContributionTypeSelectOption from "./types/billing-contribution-type-select-option";
import { BillingFormDataContributions } from "./types/billing-form.data";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: true,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: true,
    [MessengerChannel.SHARED]: true,
    [MessengerChannel.TAXI]: true,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const getContributionDiscountTypeSelectOptions =
  (): BillingContributionTypeSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getCargoTaxiContributionsTranslations();

    return [
      {
        label: translations.discount.type.optimization,
        value: BillingsCargoTaxiContributionDiscountType.DISCOUNT_OPTIMIZATION,
      },
      {
        label: translations.discount.type.beingLate,
        value: BillingsCargoTaxiContributionDiscountType.DISCOUNT_BEING_LATE,
      },
      {
        label: translations.discount.type.incompatibleCar,
        value:
          BillingsCargoTaxiContributionDiscountType.DISCOUNT_INCOMPATIBLE_CAR,
      },
      {
        label: translations.discount.type.other,
        value: BillingsCargoTaxiContributionDiscountType.DISCOUNT_OTHER,
      },
      {
        label: translations.discount.type.percentage,
        value:
          BillingsCargoTaxiContributionDiscountType.CONTRACT_CORRECTION_PERCENTAGE,
      },
    ];
  };

const getMaxContributionsFromTabIndex = (): number => {
  return Object.keys(BillingsCargoTaxiContributionDiscountType).length;
};

const getNewContribution = (): BillingsCargoTaxiContributions => {
  return billingsCargoTaxiContributionsDiscountHelper.getDiscountNewContribution();
};

const getContributionsByType = (
  contributions: BillingFormDataContributions
): BillingsCargoTaxiContributions[] => {
  return contributions.discount;
};

const billingsCargoTaxiHelper = {
  getMessengerChannelAvailability,
  getMaxContributionsFromTabIndex,
  getNewContribution,
  getContributionsByType,
  getContributionDiscountTypeSelectOptions,
};

export default billingsCargoTaxiHelper;
