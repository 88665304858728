import { TaxiTransferedOrderSettlementListingResponseDataItem } from "../api/taxi-transfered-order-settlement-listing.response";
import TaxiTransferedOrderSettlementListingItem from "../types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../types/taxi-transfered-order-settlement-listing-item-billing-status";

const createListingItemStatus = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REOPEN_REQUEST;
    default:
      return undefined;
  }
};

const createListingItem = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItem => {
  return {
    status: createListingItemStatus(responseDataItem),
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.cargo_order.human_id,
    orderStartDate: new Date(responseDataItem.start_time),
    orderTransferDate: new Date(responseDataItem.forwarding_time),
    producerTaxi: responseDataItem.producer.name,
    consumerTaxi: responseDataItem.consumer.name,
    billingModel: responseDataItem.billing_model,
    distance: responseDataItem.distance,
    distanceCost: responseDataItem.distance_amount,
    distanceRate: responseDataItem.distance_rate,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    totalCost: responseDataItem.all_contributions_amount,
    sumOfBonuses: responseDataItem.sum_of_bonuses ?? 0,
    sumOfPenalties: responseDataItem.sum_of_penalties ?? 0,
    canBeModified: responseDataItem.can_be_modified,
    contractName: responseDataItem.contract_name,
  };
};

const createListingItems = (
  responseDataItems: TaxiTransferedOrderSettlementListingResponseDataItem[]
): TaxiTransferedOrderSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const taxiTransferedOrderSettlementListingFactory = {
  createListingItems,
};

export default taxiTransferedOrderSettlementListingFactory;
