import BillingModel from "../../../../common/types/billing-model";
import TaxiOrderSettlementListingItemBillingStatus from "../types/taxi-order-settlement-listing-item-billing-status";
import TaxiOrderSettlementListingItemBillingType from "../types/taxi-order-settlement-listing-item-billing-type";

export enum TaxiOrderSettlementListingReportRequestOrder {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_AMOUNT_ASC = "DISTANCE_AMOUNT_ASC",
  DISTANCE_AMOUNT_DESC = "DISTANCE_AMOUNT_DESC",
  HALTING_AMOUNT_ASC = "HALTING_AMOUNT_ASC",
  HALTING_AMOUNT_DESC = "HALTING_AMOUNT_DESC",
  HIGHWAY_CHARGE_ASC = "HIGHWAY_CHARGE_ASC",
  HIGHWAY_CHARGE_DESC = "HIGHWAY_CHARGE_DESC",
  TOTAL_AMOUNT_ASC = "TOTAL_AMOUNT_ASC",
  TOTAL_AMOUNT_DESC = "TOTAL_AMOUNT_DESC",
}

type TaxiOrderSettlementListingReportRequest = {
  order: TaxiOrderSettlementListingReportRequestOrder | undefined;
  human_id: number | undefined;
  plan_entry_human_ids: number | undefined;
  date_since: string | undefined;
  date_to: string | undefined;
  cargo_company: string | undefined;
  status: TaxiOrderSettlementListingItemBillingStatus | undefined;
  billing_model: BillingModel | undefined;
  billing_type: TaxiOrderSettlementListingItemBillingType | undefined;
  responsible_taxi: string | undefined;
};

export default TaxiOrderSettlementListingReportRequest;
