import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingRouteDetailsTableColumn from "./types/billing-route-details-table-column";

const getTableColumns = (): BillingRouteDetailsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getRouteSummaryTranslations().table.headers;

  const tableColumns: BillingRouteDetailsTableColumn[] = [
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      accessor: "distanceCost",
      colSpan: 10,
    },
    {
      header: translations.haltingCostLabel,
      title: translations.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 10,
    },
    {
      header: translations.highwayCostLabel,
      title: translations.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 10,
    },
  ];

  return tableColumns;
};

const billingRouteDetailsHelper = {
  getTableColumns,
};

export default billingRouteDetailsHelper;
