import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import delegationTranslationsHelper from "../../../../languages/delegation-translations.helper";
import DelegationDetailsPassenger from "./types/delegation-details-passenger";

const getPassengerTitle = (passenger: DelegationDetailsPassenger): string => {
  const translations =
    delegationTranslationsHelper.getDelegationDetailsTranslations().worker;

  return translations.workerTitleTemplate
    .replace("#{worker}", passenger.displayName)
    .replace("#{mobileNumber}", passenger.phoneNumber);
};

const getMileageDetailsMessengerChannelAvailability =
  (): MessengerChannelAvailability => {
    return {
      [MessengerChannel.BILLING]: false,
      [MessengerChannel.CARGO]: false,
      [MessengerChannel.DRIVER]: false,
      [MessengerChannel.RAILY]: false,
      [MessengerChannel.SHARED]: false,
      [MessengerChannel.TAXI]: false,
      [MessengerChannel.MILEAGE_PASSENGER]: false,
      [MessengerChannel.MILEAGE_NOTES]: false,
      [MessengerChannel.DELEGATION_NOTES]: true,
    };
  };

const delegationDetailsHelper = {
  getPassengerTitle,
  getMileageDetailsMessengerChannelAvailability,
};

export default delegationDetailsHelper;
