import MessengerChannel from "../../../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../../../common/components/messenger/types/messenger-channel-availability";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";

const messengerTranslations =
  userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
    .messenger;

const getMessengerPostsHeadingText = (solvedOrderId: number | undefined) => {
  if (solvedOrderId) {
    return messengerTranslations.headingTemplateText.replace(
      "#{routeId}",
      String(solvedOrderId)
    );
  }
};

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: false,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: false,
    [MessengerChannel.SHARED]: false,
    [MessengerChannel.TAXI]: false,
    [MessengerChannel.MILEAGE_PASSENGER]: false,
    [MessengerChannel.MILEAGE_NOTES]: false,
    [MessengerChannel.DELEGATION_NOTES]: false,
  };
};

const DriverDetailsActiveRoutePlansHelper = {
  getMessengerPostsHeadingText,
  getMessengerChannelAvailability,
};

export default DriverDetailsActiveRoutePlansHelper;
