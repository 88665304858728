import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiOrderSettlementListingStatsSummaryTableColumn from "../types/taxi-order-settlement-listing-stats-summary-table-column";

const getColumns = (): TaxiOrderSettlementListingStatsSummaryTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .stats.table.headers;

  const tableColumns: TaxiOrderSettlementListingStatsSummaryTableColumn[] = [
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.haltingCostLabel,
      title: translations.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 14,
    },
    {
      header: translations.highwayCostLabel,
      title: translations.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 14,
    },
    {
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      accessor: "distanceCost",
      colSpan: 12,
    },
    {
      header: translations.sumOfDiscountsLabel,
      title: translations.sumOfDiscountsTitle,
      accessor: "sumOfDiscounts",
      colSpan: 12,
    },
    {
      header: translations.totalLabel,
      title: translations.totalTitle,
      accessor: "total",
      colSpan: 14,
    },
  ];

  return tableColumns;
};

const formatNumber = (value: number) => {
  return (+value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const taxiOrderSettlementListingStatsSummaryHelper = {
  formatNumber,
  getColumns,
};

export default taxiOrderSettlementListingStatsSummaryHelper;
