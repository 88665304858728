import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";
import BillingNode from "./types/billing-node";

export enum BillingRecalculateRequestContributionType {
  BASE_AMOUNT = "BASE_AMOUNT",
  CHARGE_HALTINGS = "CHARGE_HALTING",
  CHARGE_HIGHWAYS = "CHARGE_HIGHWAY",
  BONUS_FAVORABLE_DISTANCE = "BONUS_FAVORABLE_DISTANCE",
  BONUS_OTHER = "BONUS_OTHER",
  PENALTY_WRONG_ROUTE = "PENALTY_WRONG_ROUTE",
  PENALTY_BEING_LATE = "PENALTY_BEING_LATE",
  PENALTY_INCOMPATIBLE_CAR = "PENALTY_INCOMPATIBLE_CAR",
  PENALTY_OTHER = "PENALTY_OTHER",
}

export type BillingRecalculateRequestBonusContributionFavorableDistance = {
  type: BillingRecalculateRequestContributionType.BONUS_FAVORABLE_DISTANCE;
  comment: string | null;
  distance: number;
  rate: number;
};

export type BillingRecalculateRequestBonusContributionOther = {
  type: BillingRecalculateRequestContributionType.BONUS_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestPenaltyContributionWrongRoute = {
  type: BillingRecalculateRequestContributionType.PENALTY_WRONG_ROUTE;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestPenaltyContributionBeingLate = {
  type: BillingRecalculateRequestContributionType.PENALTY_BEING_LATE;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestPenaltyContributionIncompatibleCar = {
  type: BillingRecalculateRequestContributionType.PENALTY_INCOMPATIBLE_CAR;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestPenaltyContributionOther = {
  type: BillingRecalculateRequestContributionType.PENALTY_OTHER;
  comment: string | null;
  amount: number;
};

export type BillingRecalculateRequestBonusContribution =
  | BillingRecalculateRequestBonusContributionFavorableDistance
  | BillingRecalculateRequestBonusContributionOther;

export type BillingRecalculateRequestPenaltyContribution =
  | BillingRecalculateRequestPenaltyContributionWrongRoute
  | BillingRecalculateRequestPenaltyContributionBeingLate
  | BillingRecalculateRequestPenaltyContributionIncompatibleCar
  | BillingRecalculateRequestPenaltyContributionOther;

export type BillingRecalculateRequestContribution =
  | BillingRecalculateRequestBonusContribution
  | BillingRecalculateRequestPenaltyContribution;

type BillingRecalculateRequest = {
  billing_nodes: BillingNode[];
  billing_contributions: BillingRecalculateRequestContribution[];
  bonus: number;
  penalty: number;
  penalty_type: PenaltyType | undefined;
  bonus_type: BonusType | undefined;
};

export default BillingRecalculateRequest;
